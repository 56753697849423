import './scripts/common';
import './scripts/common-nav';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as regionSlider from './scripts/regionSlider';
regionSlider.init();

import * as ajaxForm from './scripts/ajax-form';
ajaxForm.init();

import * as ajaxFormExtend from './scripts/ajax-form-extend';
ajaxFormExtend.init();

import * as gallery from './scripts/gallery';
gallery.init();

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();

import * as lightbox from './scripts/elements-packages/lightbox';
lightbox.init();

import * as playVideo from './scripts/playVideo';
playVideo.init();