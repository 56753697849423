"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, removeClass, findIn} from "@elements/dom-utils";

export function init() {
    onFind('.js-ajax-form', function ($element) {
        on('fetch.ajax-form', function () {
            let offset = $element.getBoundingClientRect().top + window.pageYOffset;

            let $filterOverlay = findIn('.js-overlay', $element);
            if ($filterOverlay && hasClass('is-open', $filterOverlay)) {
                removeClass('is-open', $filterOverlay);
            }

            if (window.pageYOffset > offset) {
                try {
                    window.scroll({
                        top: offset,
                        behavior: 'smooth'
                    });
                } catch (e) {
                    window.scrollTo(0, offset)
                }
            }
        }, $element);
    });
}